<!-- eslint-disable import/no-cycle -->
<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col
        cols="12"
        sm="4"
      >
        <b-card
          title="OpenAM Single Sign On"
          img-top
          img-height="400"
          img-src="https://repository-images.githubusercontent.com/99786834/ae38f500-b87a-11e9-8af2-26df0a8236e5"
        >

          <b-card-text>
            Open Access Management (OpenAM) is an access management solution that includes Authentication, SSO,
            Authorization, Federation, Entitlements and Web Services Security.
          </b-card-text><br><br>
          <b-card-text><strong>Client ID: </strong>urn:marketplacecms.dev.asiapacific.pwc.com</b-card-text>
          <b-card-text><strong>Client secret: </strong>AclR**********l6JUu</b-card-text>
          <b-card-text><strong>Authorize endpoint:
          </strong>https://login-stg.pwc.com/openam/oauth2/authorize</b-card-text>
          <b-card-text><strong>Redirect URI:
          </strong>https://marketplacecms.dev.asiapacific.pwc.com/sso</b-card-text>
          <b-card-text><strong>Logout URI: </strong>https://marketplacecms.dev.asiapacific.pwc.com</b-card-text>

        </b-card>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <b-card
          title="Password Module"
          img-top
          img-height="400"
          img-src="https://www.rd.com/wp-content/uploads/2020/02/GettyImages-901412996-scaled-e1650398409723.jpg?fit=700,1024"
        >
          <b-card-text>
            • At least one special character (@ # $ %)
          </b-card-text>
          <b-card-text>
            • At least one upper case letter
          </b-card-text>
          <b-card-text>
            • At least one lower case letter
          </b-card-text>
          <b-card-text>
            • At least one numeric digit (0,9)
          </b-card-text>
          <b-card-text>
            • At least 8 characters
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <b-card
          title="Stripe Payment Gateway"
          img-top
          img-height="400"
          img-src="https://images.ctfassets.net/fzn2n1nzq965/3AGidihOJl4nH9D1vDjM84/9540155d584be52fc54c443b6efa4ae6/homepage.png?q=80"
        >
          <b-card-text>
            A fully integrated suite of payments products
          </b-card-text><br><br>
          <b-card-text><strong>Secret key:
          </strong>sk_test_51Ibf7CHUWBo3hL7xsvV*****************************ZuDTq</b-card-text>
          <b-card-text><strong>Publish key:
          </strong>pk_test_51Ibf7CHUWBo3hL7xDL4gcaFB5N*********************to00hLBO9b6O</b-card-text>
        </b-card>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import {
  BRow, BCardText, BCol, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
  },
  data() {
    return {
      data: {
      },
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
